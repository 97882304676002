<template>
  <div class="seperator-wrapper">
    <hr />
    <span v-bind:class="[backgroundClass ? `bg-${backgroundClass}` : '']">
      {{ text }}
    </span>
  </div>
</template>
<script>
export default {
  name: "WeDivSeperator",
  props: {
    text: "",
    backgroundClass: { default: "white" },
  },
};
</script>
<style lang="scss" scoped>
.seperator-wrapper {
  width: 100%;
  position: relative;
  span {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 0;
    padding: 0 10px;
    font-weight: bold;
    color: gray;
    user-select: none;
  }
}
</style>
